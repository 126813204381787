import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Textarea } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "textarea"
    }}>{`Textarea`}</h1>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<Textarea placeholder=\"Placeholder\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Textarea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Textarea placeholder="Placeholder" mdxType="Textarea" />
    </Playground>
    <h2 {...{
      "id": "states"
    }}>{`States`}</h2>
    <Playground __position={1} __code={'<Textarea />\n<Textarea placeholder=\"Placeholder\" />\n<Textarea placeholder=\"Multiple rows\" rows=\"4\" />\n<Textarea>This is a value</Textarea>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Textarea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Textarea mdxType="Textarea" />
  <Textarea placeholder="Placeholder" mdxType="Textarea" />
  <Textarea placeholder="Multiple rows" rows="4" mdxType="Textarea" />
  <Textarea mdxType="Textarea">This is a value</Textarea>
    </Playground>
    <h2 {...{
      "id": "alternative"
    }}>{`Alternative`}</h2>
    <p>{`In flows with only a few fields you might use the prop `}<inlineCode parentName="p">{`alternative`}</inlineCode>{` to get a gray textarea field that stands out more.`}</p>
    <Playground __position={2} __code={'<Textarea alternative placeholder=\"Alternative\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Textarea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Textarea alternative placeholder="Alternative" mdxType="Textarea" />
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Textarea} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      